<material-navbar
        menuPosition="custom-page-navbar"
             *ngIf="!(page$ | async)?.hide_nav && !settings.get('vebto.customPages.hideNavbar') && showNavbar"
></material-navbar>

<ad-host-drive slot="ads.drive"></ad-host-drive>


<div class="page-body container article-body" [innerHTML]="body$ | async"></div>


<app-footer class="be-container"></app-footer>
