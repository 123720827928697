<div class="hero header-overlay" [style.background]="overlayBackground">
    <div
        class="background-image"
        [style.background-image]="'url(' + content.headerImage + ')'"
        [style.opacity]="content.headerImageOpacity"
    ></div>
    <div class="hero-content">
        <material-navbar
            [transparent]="true"
            class="login-button-white"
            menuPosition="homepage-navbar"
        ></material-navbar>
        <div class="content">
            <div class="title" trans>{{ content.headerTitle}}</div>
            <div class="subtitle" trans>{{ content.headerSubtitle }}</div>
            <div class="action-buttons">
                <a
                    mat-raised-button
                    color="accent"
                    class="cta-button"
                    routerLink="/register"
                    *ngIf="content.actions?.cta1"
                    trans
                    >{{ content.actions.cta1 }}</a
                >
                <a
                    mat-button
                    class="pricing-button"
                    routerLink="/billing/pricing"
                    *ngIf="content.actions?.cta2"
                    trans
                    >{{ content.actions.cta2 }}</a
                >
            </div>
        </div>
    </div>
    <button mat-icon-button class="down-button" (click)="scrollToFeatures()">
        <mat-icon svgIcon="keyboard-arrow-down"></mat-icon>
    </button>
</div>

<ng-container *ngIf="content.primaryFeatures.length">
    <div class="be-container">

        <ad-host-preview slot="ads.landing.top" class="home-ads-top"></ad-host-preview>

        <ul class="inline-features unstyled-list">
            <li class="inline-feature" *ngFor="let primaryFeature of content.primaryFeatures">
                <image-or-icon class="inherit-size" [src]="primaryFeature.image"></image-or-icon>
                <h3 trans>{{ primaryFeature.title }}</h3>
                <p trans>{{ primaryFeature.subtitle }}</p>
            </li>
        </ul>
    </div>

    <div class="spacer"></div>
</ng-container>

<ul class="big-features unstyled-list">
    <ng-container
        *ngFor="
            let secondaryFeature of content.secondaryFeatures;
            first as first;
            last as last;
            even as even
        "
    >
        <li
            class="big-feature be-container"
            [class.first-secondary-feature]="first"
            [class.reverse]="even"
        >
            <img [src]="secondaryFeature.image + '?v2'" />
            <section class="info">
                <small trans>{{ secondaryFeature.subtitle }}</small>
                <h2 trans>{{ secondaryFeature.title }}</h2>
                <div class="feature-border"></div>
                <p trans>{{ secondaryFeature.description }}</p>
            </section>
        </li>

        <div class="spacer" *ngIf="!last"></div>
    </ng-container>

</ul>

<div class="spacer"></div>

<ad-host-preview slot="ads.file-preview" class="home-ads-bottom"></ad-host-preview>

<div class="bottom-cta" [style.background-image]="'url(' + content.footerImage + ')'">
    <div class="be-container">
        <h2 class="footer-title" trans>{{ content.footerTitle }}</h2>
        <p class="footer-description" trans>{{ content.footerSubtitle }}</p>
        <a mat-stroked-button routerLink="/login" *ngIf="content.actions?.cta3" trans>{{
            content.actions.cta3
        }}</a>
    </div>
</div>

<app-footer class="be-container"></app-footer>
