import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdHostDriveComponent} from '@common/core/ui/ad-host-drive/ad-host-drive.component';


@NgModule({
    declarations: [
        AdHostDriveComponent,
    ],
    imports: [
        CommonModule
    ],
    exports: [
        AdHostDriveComponent,
    ]
})
export class AdHostDriveModule {
}
