import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdHostPreviewComponent} from './ad-host-preview.component';


@NgModule({
    declarations: [
        AdHostPreviewComponent,
    ],
    imports: [
        CommonModule
    ],
    exports: [
        AdHostPreviewComponent,
    ]
})
export class AdHostPreviewModule {
}
